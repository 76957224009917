<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit, valid }"
    >
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Transaction Invoice"
          rules="required"
        >
          <label>Transaction Invoice </label>
          <b-input-group size="sm">
            <b-form-input
              v-model="actualForm.transaction_invoice"
              size="sm"
              :disabled="showSearchTransactionInvoiceResult && resultOk"
              :state="errors.length > 0 ? false:null"
              placeholder="Transaction Invoice"
            />
            <b-input-group-append>
              <b-button
                v-if="!showSearchTransactionInvoiceResult"
                :disabled="!actualForm.transaction_invoice || actualForm.transaction_invoice.length < 5"
                variant="secondary"
                @click="searchTxnInvoice"
              >
                Search
              </b-button>
              <b-button
                v-if="showSearchTransactionInvoiceResult"
                variant="danger"
                @click="clearTxnInvoiceNoField"
              >
                Clear Search
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <div
          v-if="showSearchTransactionInvoiceResult"
          class="my-1"
        >
          <template v-if="resultOk">
            <b-alert
              show
              class="p-1 mb-1"
              variant="success"
            >
              Result Found
            </b-alert>
          </template>
          <template v-if="!resultOk">
            <b-alert
              show
              class="p-1"
              variant="danger"
            >
              Result Not Found
            </b-alert>
          </template>
        </div>
      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Settlement Report Date"
          rules="required"
        >
          <label>Settlement Report Date </label>
          <b-form-input
            v-model="rowData.settlement_report_date"
            size="sm"
            disabled
            :state="errors.length > 0 ? false:null"
            placeholder="Settlement Report Date"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Settlement ID"
          rules="required"
        >
          <label>Settlement ID </label>
          <b-form-input
            v-model="rowData.id"
            size="sm"
            disabled
            :state="errors.length > 0 ? false:null"
            placeholder="Settlement ID"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          rules="required|gte:1"
          mode="lazy"
          name="Total Sale"
        >
          <label>Total Revenue  </label>
          <vue-numeric
            v-model="rowData.total_revenue"
            disabled
            :class="[{'is-invalid': errors.length > 0 }]"
            class="form-control form-control-sm text-right"
            currency="MYR"
            :precision="2"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>

        <validation-provider
          #default="{ errors }"
          rules="required"
          name="Adjustment Type"
        >
          <label>Adjustment Type </label>
          <v-select
            v-model="actualForm.adjustment_type"
            :reduce="title =>title.value"
            :class="[{'is-invalid': errors.length > 0 },{'select-size-sm': fieldSize === 'sm'},{'select-size-lg': fieldSize === 'lg'}, {'select-size-md': fieldSize === 'md'}]"
            label="name"
            placeholder="Please Select"
            :options="[{name: 'DEDUCT FROM SETTLEMENT', value: 'SUBTRACTION'},{name: 'ADD TO SETTLEMENT', value: 'ADDITION'},]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          :rules="{required: true, checkAdjustmentValue: [0.01, actualForm.adjustment_type, rowData.total_revenue]}"
          mode="lazy"
          name="Adjustment Value"
        >
          <label>Adjustment Value </label>
          <vue-numeric
            v-model="actualForm.adjustment_value"
            disabled
            :class="[{'is-invalid': errors.length > 0 }]"
            class="form-control form-control-sm text-right"
            currency="MYR"
            :precision="2"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

      </b-form-group>

      <template v-if="resultOk && showSearchTransactionInvoiceResult">
        <div
          class="w-100"
          style="height: 40px"
        >

          <b-form-checkbox
            v-model="isFeeAdjustment"
            checked="false"
            switch
            inline
          >
            Fee Charge
          </b-form-checkbox>
        </div>

        <div v-if="isFeeAdjustment">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Settlement Report Date"
              rules="required|gte:0|max_value:100"
            >
              <label>Charges (%) </label>
              <vue-numeric
                v-model="feeCharge"
                currency="%"
                currency-symbol-position="suffix"
                :class="[{'is-invalid': errors.length > 0 }]"
                class="form-control form-control-sm text-right"
                :precision="2"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </template>

      <b-form-group v-if="isFeeAdjustment">
        <validation-provider
          #default="{ errors }"
          rules="required|gte:1"
          mode="lazy"
          name="Total Sale"
        >
          <label>Calculated Fee  </label>
          <vue-numeric
            :value="calculatedFee"
            disabled
            :class="[{'is-invalid': errors.length > 0 }]"
            class="form-control form-control-sm text-right"
            currency="MYR"
            :precision="2"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Adjustment By"
          rules="required"
        >
          <label>Adjustment By </label>
          <v-select
            v-model="actualForm.adjustment_by"
            :reduce="title =>title.value"
            :class="[{'is-invalid': errors.length > 0 },{'select-size-sm': fieldSize === 'sm'},{'select-size-lg': fieldSize === 'lg'}, {'select-size-md': fieldSize === 'md'}]"
            label="name"
            placeholder="Please Select"
            :options="[{name: 'Syahirah', value: 'Syahirah'},{name: 'Azrul', value: 'Azrul'},]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Approved By"
          rules="required"
        >
          <label>Approved By </label>
          <v-select
            v-model="actualForm.approve_by"
            :reduce="title =>title.value"
            :class="[{'is-invalid': errors.length > 0 },{'select-size-sm': fieldSize === 'sm'},{'select-size-lg': fieldSize === 'lg'}, {'select-size-md': fieldSize === 'md'}]"
            label="name"
            placeholder="Please Select"
            :options="[{name: 'Syahirah', value: 'Syahirah'},{name: 'Azrul', value: 'Azrul'},]"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Reason"
          rules="required"
        >
          <label>Reason </label>
          <b-form-textarea
            v-model="actualForm.reason"
            size="sm"
            :state="errors.length > 0 ? false:null"
            placeholder="Reason"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div class="mt-4">
        <b-button
          type="submit"
          variant="primary"
          :disabled="!resultOk"
          @click="handleSubmit(onSubmit)"
        >
          Submit
        </b-button>
        <b-button
          class="ml-1"
          type="reset"
          variant="secondary"
          @click="cancel"
        >Cancel</b-button>
      </div>

    </ValidationObserver>

  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

extend('checkAdjustmentValue', (value, args) => {
  if (args[1] === undefined) {
    return 'Please Select Adjustment Type'
  }
  if (value < args[0]) {
    return `This value must be more or equal to ${args[0]}`
  }

  if (args[1] === 'SUBTRACTION' && value > args[2]) {
    return `This value must not more than ${parseFloat(args[2]).toFixed(2)}`
  }

  return true
})

extend('gte', (value, args) => {
  const paramValue = parseInt(args[0], 10)

  console.log(args)
  if (value >= paramValue) {
    return true
  }
  if (value === null) {
    return false
  }

  return `This value must be more or equal to ${args}`
})

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    rowData: {
      required: true,
      type: Object,
      default() {
        return {

        }
      },
    },
  },
  data() {
    return {
      showSearchTransactionInvoiceResult: false,
      resultOk: true,
      fieldSize: 'sm',
      actualForm: {

      },
      isFeeAdjustment: false,
      feeCharge: 0,
    }
  },
  computed: {
    calculatedFee() {
      if (this.feeCharge > 0) {
        const num = (this.feeCharge / 100) * this.actualForm.adjustment_value
        return Math.round((num + Number.EPSILON) * 100) / 100
      } return 0
    },
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    submissionForm() {
      return {
        ...this.actualForm,
        settlement_id: this.rowData.id,
        transaction_date: this.rowData.settlement_report_date,
        transaction_invoice: this.actualForm.transaction_invoice,
      }
    },
  },
  mounted() {

  },
  methods: {
    async checkInvoiceNo(invoiceNo) {
      const payload = {
        _invoice_no: invoiceNo,
      }
      this.$store.dispatch('settlement/settlementCheckInvoiceNo', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.resultOk = true
          if (res.data.data.total) {
            this.actualForm.adjustment_value = res.data.data.total
          }
          this.showSearchTransactionInvoiceResult = true
        }
        if (res.data.response_code === 6722) {
          this.resultOk = false
          this.showSearchTransactionInvoiceResult = true
        }
      })
    },
    searchTxnInvoice() {
      if (this.actualForm.transaction_invoice && this.actualForm.transaction_invoice.length) {
        this.checkInvoiceNo(this.actualForm.transaction_invoice)
      }
    },
    onSubmit() {
      if (this.isFeeAdjustment) {
        const payload = {
          ...this.submissionForm,
          transaction_invoice: `${this.submissionForm.transaction_invoice}-FEE`,
          adjustment_value: this.calculatedFee,

        }
        console.log(payload)
        this.$emit('submitclicked', payload)
      } else {
        console.log('not fee')
        this.$emit('submitclicked', this.submissionForm)
      }
    },
    clearTxnInvoiceNoField() {
      this.resultOk = false
      this.showSearchTransactionInvoiceResult = false
      this.actualForm.transaction_invoice = ''
      this.actualForm.adjustment_value = 0
    },
    cancel() {
      this.$bvModal.hide('settlementAdjustmentAdminModal')
    },
  },
}
</script>
