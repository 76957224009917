var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Transaction Invoice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Transaction Invoice ")]),_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":_vm.showSearchTransactionInvoiceResult && _vm.resultOk,"state":errors.length > 0 ? false:null,"placeholder":"Transaction Invoice"},model:{value:(_vm.actualForm.transaction_invoice),callback:function ($$v) {_vm.$set(_vm.actualForm, "transaction_invoice", $$v)},expression:"actualForm.transaction_invoice"}}),_c('b-input-group-append',[(!_vm.showSearchTransactionInvoiceResult)?_c('b-button',{attrs:{"disabled":!_vm.actualForm.transaction_invoice || _vm.actualForm.transaction_invoice.length < 5,"variant":"secondary"},on:{"click":_vm.searchTxnInvoice}},[_vm._v(" Search ")]):_vm._e(),(_vm.showSearchTransactionInvoiceResult)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.clearTxnInvoiceNoField}},[_vm._v(" Clear Search ")]):_vm._e()],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.showSearchTransactionInvoiceResult)?_c('div',{staticClass:"my-1"},[(_vm.resultOk)?[_c('b-alert',{staticClass:"p-1 mb-1",attrs:{"show":"","variant":"success"}},[_vm._v(" Result Found ")])]:_vm._e(),(!_vm.resultOk)?[_c('b-alert',{staticClass:"p-1",attrs:{"show":"","variant":"danger"}},[_vm._v(" Result Not Found ")])]:_vm._e()],2):_vm._e()],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Settlement Report Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Settlement Report Date ")]),_c('b-form-input',{attrs:{"size":"sm","disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Settlement Report Date"},model:{value:(_vm.rowData.settlement_report_date),callback:function ($$v) {_vm.$set(_vm.rowData, "settlement_report_date", $$v)},expression:"rowData.settlement_report_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Settlement ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Settlement ID ")]),_c('b-form-input',{attrs:{"size":"sm","disabled":"","state":errors.length > 0 ? false:null,"placeholder":"Settlement ID"},model:{value:(_vm.rowData.id),callback:function ($$v) {_vm.$set(_vm.rowData, "id", $$v)},expression:"rowData.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|gte:1","mode":"lazy","name":"Total Sale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Total Revenue ")]),_c('vue-numeric',{staticClass:"form-control form-control-sm text-right",class:[{'is-invalid': errors.length > 0 }],attrs:{"disabled":"","currency":"MYR","precision":2},model:{value:(_vm.rowData.total_revenue),callback:function ($$v) {_vm.$set(_vm.rowData, "total_revenue", $$v)},expression:"rowData.total_revenue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Adjustment Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Adjustment Type ")]),_c('v-select',{class:[{'is-invalid': errors.length > 0 },{'select-size-sm': _vm.fieldSize === 'sm'},{'select-size-lg': _vm.fieldSize === 'lg'}, {'select-size-md': _vm.fieldSize === 'md'}],attrs:{"reduce":function (title) { return title.value; },"label":"name","placeholder":"Please Select","options":[{name: 'DEDUCT FROM SETTLEMENT', value: 'SUBTRACTION'},{name: 'ADD TO SETTLEMENT', value: 'ADDITION'} ]},model:{value:(_vm.actualForm.adjustment_type),callback:function ($$v) {_vm.$set(_vm.actualForm, "adjustment_type", $$v)},expression:"actualForm.adjustment_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":{required: true, checkAdjustmentValue: [0.01, _vm.actualForm.adjustment_type, _vm.rowData.total_revenue]},"mode":"lazy","name":"Adjustment Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Adjustment Value ")]),_c('vue-numeric',{staticClass:"form-control form-control-sm text-right",class:[{'is-invalid': errors.length > 0 }],attrs:{"disabled":"","currency":"MYR","precision":2},model:{value:(_vm.actualForm.adjustment_value),callback:function ($$v) {_vm.$set(_vm.actualForm, "adjustment_value", $$v)},expression:"actualForm.adjustment_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.resultOk && _vm.showSearchTransactionInvoiceResult)?[_c('div',{staticClass:"w-100",staticStyle:{"height":"40px"}},[_c('b-form-checkbox',{attrs:{"checked":"false","switch":"","inline":""},model:{value:(_vm.isFeeAdjustment),callback:function ($$v) {_vm.isFeeAdjustment=$$v},expression:"isFeeAdjustment"}},[_vm._v(" Fee Charge ")])],1),(_vm.isFeeAdjustment)?_c('div',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Settlement Report Date","rules":"required|gte:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Charges (%) ")]),_c('vue-numeric',{staticClass:"form-control form-control-sm text-right",class:[{'is-invalid': errors.length > 0 }],attrs:{"currency":"%","currency-symbol-position":"suffix","precision":2},model:{value:(_vm.feeCharge),callback:function ($$v) {_vm.feeCharge=$$v},expression:"feeCharge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()]:_vm._e(),(_vm.isFeeAdjustment)?_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|gte:1","mode":"lazy","name":"Total Sale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Calculated Fee ")]),_c('vue-numeric',{staticClass:"form-control form-control-sm text-right",class:[{'is-invalid': errors.length > 0 }],attrs:{"value":_vm.calculatedFee,"disabled":"","currency":"MYR","precision":2}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Adjustment By","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Adjustment By ")]),_c('v-select',{class:[{'is-invalid': errors.length > 0 },{'select-size-sm': _vm.fieldSize === 'sm'},{'select-size-lg': _vm.fieldSize === 'lg'}, {'select-size-md': _vm.fieldSize === 'md'}],attrs:{"reduce":function (title) { return title.value; },"label":"name","placeholder":"Please Select","options":[{name: 'Syahirah', value: 'Syahirah'},{name: 'Azrul', value: 'Azrul'} ]},model:{value:(_vm.actualForm.adjustment_by),callback:function ($$v) {_vm.$set(_vm.actualForm, "adjustment_by", $$v)},expression:"actualForm.adjustment_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Approved By","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Approved By ")]),_c('v-select',{class:[{'is-invalid': errors.length > 0 },{'select-size-sm': _vm.fieldSize === 'sm'},{'select-size-lg': _vm.fieldSize === 'lg'}, {'select-size-md': _vm.fieldSize === 'md'}],attrs:{"reduce":function (title) { return title.value; },"label":"name","placeholder":"Please Select","options":[{name: 'Syahirah', value: 'Syahirah'},{name: 'Azrul', value: 'Azrul'} ]},model:{value:(_vm.actualForm.approve_by),callback:function ($$v) {_vm.$set(_vm.actualForm, "approve_by", $$v)},expression:"actualForm.approve_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Reason ")]),_c('b-form-textarea',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Reason"},model:{value:(_vm.actualForm.reason),callback:function ($$v) {_vm.$set(_vm.actualForm, "reason", $$v)},expression:"actualForm.reason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":!_vm.resultOk},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v(" Submit ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"reset","variant":"secondary"},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }